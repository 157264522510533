/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { SelfUserloginApi, loginApi } from "../services/auth";
import { getProfile } from "../services/users";
import { StatusCodes } from "http-status-codes";
import WidgetsLoader from "../components/WidgetsLoader";
import { useNavigate } from "react-router-dom";

// Create an AuthContext for managing authentication state
export const AuthContext = createContext(null);

// Constants for storing access and refresh tokens in local storage
const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

// Functions for setting and getting access and refresh tokens
export const setAuthToken = (accessToken) => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const setRefreshToken = (accessToken) => {
  window.localStorage.setItem(REFRESH_TOKEN_KEY, accessToken);
};

export const getAuthToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

// AuthProvider component for managing authentication state
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authenticationError, setAuthenticationError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Function to bootstrap authentication on component mount
  const bootstrapAuth = async () => {
    try {
      setIsLoading(true);
      const { isOk, data } = await getProfile();


      // Set authentication value and user data
      if (isOk) {
        setUser(data);

        // Check if user must change their password
        // if (!data["user_must_change_password"]) {
        // }
        return setIsAuthenticated(true);
      }

      // If authentication fails or user must change password, set isAuthenticated to false
      return setIsAuthenticated(false);
    } catch (e) {
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bootstrapAuth();
  }, []);

  // Function to handle email/password login
  const loginWithEmailPassword = useCallback(
    async (email, password) => {
      try {
        setAuthenticationError("");
        const { isOk, data, status, error } = await loginApi(email, password);
        // If login is successful and tokens are received
        if (
          isOk &&
          data.tokens &&
          data.tokens.access_token &&
          data.tokens.refresh_token
        ) {
          const { access_token, refresh_token } = data.tokens;
          setAuthToken(access_token);
          setRefreshToken(refresh_token);
          await bootstrapAuth();

          // Redirect to the appropriate route based on password change requirement
          if (data.password_change_required) {
            navigate("/add-password");
          } else {
            navigate("/");
          }
        }

        // If there are validation or authentication errors, set authenticationError
        if (
          status === StatusCodes.BAD_REQUEST ||
          status === StatusCodes.UNAUTHORIZED
        ) {
          for (const [, value] of Object.entries(error)) {
            return { isOk: isOk, status: status, error: value };
          }
        }

        // Clear authenticationError if there are no errors
        return setAuthenticationError("");
      } catch (e) {
        setAuthenticationError("Something went wrong");
        return e;
      }
    },
    [navigate]
  );

  // Function to handle company login
  const companyLogin = useCallback(
    async (email, password, keep_me_logged_in) => {
      try {
        setAuthenticationError("");
        const { isOk, data, status, error } = await SelfUserloginApi(
          email,
          password,
          keep_me_logged_in
        );
        // If login is successful and tokens are received
        if (
          isOk &&
          data.tokens &&
          data.tokens.access_token &&
          data.tokens.refresh_token
        ) {
          const { access_token, refresh_token } = data.tokens;
          setAuthToken(access_token);
          setRefreshToken(refresh_token);
          await bootstrapAuth();

          // Redirect to the appropriate route based on password change requirement
          if (data.password_change_required) {
            navigate("/add-password");
          } else {
            // {login_url == 'admin' && data.role == 'admin' ?  navigate("/users")   :  navigate("/time-sheet")  }
            {
              data.role == "admin"
                ? navigate(`/${data.company_url}/users`)
                : navigate(`/${data.company_url}/time-sheet`);
            }
          }
        }

        // If there are validation or authentication errors, set authenticationError
        if (
          status === StatusCodes.BAD_REQUEST ||
          status === StatusCodes.UNAUTHORIZED
        ) {
          for (const [, value] of Object.entries(error)) {
            return { isOk: isOk, status: status, error: value };
          }
        }

        // Clear authenticationError if there are no errors
        return setAuthenticationError("");
      } catch (e) {
        setAuthenticationError("Something went wrong");
        return e;
      }
    },
    [navigate]
  );

  // Create a memoized context value to prevent unnecessary renders
  const values = useMemo(() => {
    return {
      user,
      loginWithEmailPassword,
      companyLogin,
      isAuthenticated,
      isLoading,
      bootstrapAuth,
      setIsAuthenticated,
      authenticationError,
    };
  }, [
    SelfUserloginApi,
    companyLogin,
    isAuthenticated,
    isLoading,
    authenticationError,
    user,
  ]);

  // Render a loading indicator while authentication is in progress
  if (isLoading) {
    return <WidgetsLoader />;
  }

  // Provide the AuthContext to child components
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
